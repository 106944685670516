<template>
  <div>
    <div class="notPrintable">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container class="container sticky-header" fluid>
        <v-card elevation="6">
          <v-row style="margin: 10px">
            <v-col cols="3">
              <h3>{{ $store.state.studentLevelOne.studentName }}</h3>
            </v-col>
            <v-col cols="3" align="center">
              <h3>قناة القبول : {{ $store.state.studentLevelOne.acceptedTypeId }}</h3>
            </v-col>

            <v-col cols="3" align="center">
              <h3 style="padding-top: 3px">
                القسم :
                {{ $store.state.studentLevelOne.sectionName }}
              </h3>
            </v-col>

            <v-col cols="2" align="center">
              <h3 style="padding-top: 3px">الرقم الجامعي : {{ $store.state.studentLevelOne.collegeNumber }}</h3>
            </v-col>

            <v-col cols="1" align="left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-2" fab small color="primary" @click="$router.go(-1)" v-bind="attrs" v-on="on">
                    <v-icon dark> mdi-arrow-left </v-icon>
                  </v-btn>
                </template>
                <span>رجوع</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <canvas id="barcode" style="position: absolute; top: -200px"></canvas>
      <v-container fluid v-if="collegeAlert != null">
        <v-alert border="left" color="indigo" dark>
          <!-- الرقم الجامعي المثبت :
          <h2>{{ collegeAlert }}</h2> -->

          <v-row style="padding: 20px">
            <v-col cols="4">
              <v-text-field :value="collegeAlert" ref="textToCopy"></v-text-field>
            </v-col>
            <v-col cols="8" style="padding: 30px 10px">
              <v-btn style="padding: 10px 20px" @click="copyText">نسخ النص</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-container>

      <!-- <v-container class="container" fluid v-if="!$store.state.studentLevelOne?.level">
        <v-card>
          <v-row>
            <v-col cols="3">
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">الترشيح الاول :</h5>
              </div>

              <div style="padding: 10px">
                <h5 style="padding-top: 3px">الترشيح الثاني :</h5>
              </div>
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">الترشيح الثالث :</h5>
              </div>
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">الترشيح الرابع :</h5>
              </div>
            </v-col>
            <v-col cols="3">
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">
                  الجامعة :
                  {{ $store.state.studentLevelOne.firstUniversity }}
                </h5>
              </div>
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">
                  الجامعة :
                  {{ $store.state.studentLevelOne.secondUniversity }}
                </h5>
              </div>
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">
                  الجامعة :
                  {{ $store.state.studentLevelOne.thirdUniversity }}
                </h5>
              </div>
              <div style="padding: 10px">
                <h5 style="padding-top: 3px">
                  الجامعة :
                  {{ $store.state.studentLevelOne.fourthUniversity }}
                </h5>
              </div>
            </v-col>
            <v-col cols="3">
              <h5 style="padding-top: 15px">القسم : {{ $store.state.studentLevelOne.firstSectionName }}</h5>
              <h5 style="padding-top: 15px">القسم : {{ $store.state.studentLevelOne.secondSectionName }}</h5>
              <h5 style="padding-top: 15px">القسم : {{ $store.state.studentLevelOne.thirdSectionName }}</h5>
              <h5 style="padding-top: 15px">القسم : {{ $store.state.studentLevelOne.fourthSectionName }}</h5>
            </v-col>
            <v-col cols="3">
              <h5 style="padding-top: 15px">الدراسة : {{ $store.state.studentLevelOne.firstStudyType }}</h5>
              <h5 style="padding-top: 15px">الدراسة : {{ $store.state.studentLevelOne.secondStudyType }}</h5>
              <h5 style="padding-top: 15px">الدراسة : {{ $store.state.studentLevelOne.thirdStudyType }}</h5>
              <h5 style="padding-top: 15px">الدراسة : {{ $store.state.studentLevelOne.fourthStudyType }}</h5>
            </v-col>
          </v-row>
        </v-card>
      </v-container> -->

      <div style="margin: 10px 0"></div>
      <v-container class="container" fluid>
        <v-card>
          <v-row>
            <v-col cols="4"
              ><AddLevelOnePay
                v-if="!locked"
                :paymentTypesAll="paymentTypesAll"
                :studentPay="$store.state.subFeeStudentDetails"
                :sectionInstallment="sectionCost.amount"
                :sectionId="sectionCost.sectionId ? sectionCost.sectionId : 1"
                @reloadTask="initialData()"
                style="padding: 10px; display: inline-block"
              />

              <h5 v-else style="padding: 10px">الرجاء ادخال القسط الدراسي</h5>
            </v-col>
            <v-col cols="4" style="text-align: center">
              <ConfirmStudent style="padding: 10px; display: inline-block" @clicked="goToStudents" :acceptedTypes="acceptedTypes" v-if="showConfirm" />
            </v-col>

            <v-col cols="4" style="text-align: left">
              <EditLevelOneAcceptedType style="padding: 10px; display: inline-block" @reloadTask="initialData()" :acceptedTypes="acceptedTypes" />
            </v-col>
          </v-row>

          <v-card style="margin: 20px 0">
            <div>
              <v-row>
                <v-col cols="6">
                  <h5 style="padding: 20px; text-align: right">طالب جديد</h5>
                </v-col>
              </v-row>
            </div>

            <v-data-table :headers="headers" :items="allStudentFees" :items-per-page="15" item-key="idApplication" class="table-content">
              <template v-slot:[`item.index`]="{ index }">
                <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
              </template>

              <template v-slot:[`item.studentLevel`]="{}">
                <p style="padding: 0 3px">حجز مقعد</p>
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                <h4 style="padding: 0 3px">
                  {{
                    item.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "IQD",
                    })
                  }}
                </h4>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <SoftDeleteStudentVoucher style="display: inline-block" :item="item" @reloadTask="initialData" v-if="$store.state.userData.roleId != 61" />
                <v-btn icon @click="printVoucher(item)">
                  <v-icon color="primary"> mdi-printer </v-icon>
                </v-btn>
                <EditSectionIdVoucher style="display: inline-block" :studentPay="item" v-if="$store.state.userData.roleId != 61" @reloadTask="initialData()" />
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import AddLevelOnePay from "../components/AddLevelOnePay.vue";
import EditSectionIdVoucher from "../components/EditSectionIdVoucher.vue";
import SoftDeleteStudentVoucher from "../components/SoftDeleteStudentVoucher.vue";
import ConfirmStudent from "../components/ConfirmStudent.vue";
import EditLevelOneAcceptedType from "../components/EditLevelOneAcceptedType.vue";
import n2wordsAR from "n2words/lib/i18n/AR.mjs";
var JsBarcode = require("jsbarcode");

export default {
  components: {
    AddLevelOnePay,
    SoftDeleteStudentVoucher,
    EditSectionIdVoucher,
    ConfirmStudent,
    EditLevelOneAcceptedType,
  },
  data: () => ({
    haveDiscount: false,
    amountPay: 0,
    sectionCost: 0,
    locked: false,
    overlay: false,
    studentPayDetails: "",
    discountTypeId: "",
    discountStudent: "",
    acceptedTypes: [],
    paymentTypes: [],
    showConfirm: true,
    sectionInstallmentStudent: 0,
    allStudentFees: [],
    paymentTypesAll: [],
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "رقم الوصل", value: "voucherNumber" },
      { text: "تسديد عن", value: "typeName" },
      { text: "للمرحلة", value: "studentLevel" },
      { text: "تاريخ الوصل", value: "paymentDateFormat" },
      { text: "المبلغ", value: "amount" },
      {
        text: " الملاحظات",
        value: "notes",
      },
      { text: "الاجراءات", value: "actions" },
    ],
    studentDiscountPercentage: "",
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    collegeAlert: null,
  }),
  created() {
    console.log(this.$store.state.studentLevelOne);
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/studentPaymentsDetailsInstallment/${self.$store.state.studentLevelOne.collegeNumber}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(
            `${self.$store.state.apiUrlFees}/sectionInstallmentByName?sectionName=${self.$store.state.studentLevelOne.firstSectionName}&studyYearId=75&level=1&studyType=${
              self.$store.state.studentLevelOne.firstStudyType == "صباحي" ? 1 : 2
            }`,
            {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            }
          ),

          self.$http.get(`${self.$store.state.apiUrlFees}/paymentTypesForContinue`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlRegistration}/acceptedTypes`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((studentPayments, sectionInstallment, allPaymentsType, acceptedTypes) => {
            self.allStudentFees = studentPayments.data;

            self.sectionCost = sectionInstallment.data.length > 0 ? sectionInstallment.data[0] : { amount: 0, level: 500 };
            self.locked = sectionInstallment.data.length > 0 ? false : true;
            console.log(self.locked);
            self.acceptedTypes = acceptedTypes.data;
            self.paymentTypesAll = allPaymentsType.data;
            self.overlay = false;

            JsBarcode("#barcode", `${self.$store.state.studentLevelOne.collegeNumber}`, {
              width: 3,
              height: 30,
              displayValue: false,
            });
          })
        )
        .catch((error) => {
          self.overlay = false;
          console.log(error);
        });
    },
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    },
    goToStudents(value) {
      let self = this;
      console.log(value);
      self.showConfirm = false;
      self.collegeAlert = value.uniqueNumber;
      // self.CopyMe(self.collegeAlert);
      // this.$router.push("/fees").catch(() => {});
    },
    CopyMe(TextToCopy) {
      var TempText = document.createElement("input");
      TempText.value = TextToCopy;
      document.body.appendChild(TempText);
      TempText.select();
      document.execCommand("copy");
      document.body.removeChild(TempText);

      alert("Copied the text: " + TempText.value);
    },
    printVoucher(voucher) {
      let self = this;
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      let image = require("../assets/logo.png");

      // Open the print window
      const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
      console.log(voucher);
      let numberWriting = n2wordsAR(voucher.amount, { lang: "ar" });
      let barcodeCanvas = document.getElementById("barcode");
      let newStyle = stylesHtml.replaceAll("portrait", "landscape");
      let lastStyle = newStyle.replaceAll("A4", "A5");

      WinPrint.document.write(`<!DOCTYPE html>
        <!DOCTYPE html>
          <html lang="en">
            ${lastStyle}
            <style>
            body ,html{ margin:0px !important}
          body{
            border:1px solid #eee !important;
          }
          @media print {
              @page {
                size: A5 landscape !important;
                margin: 0cm;
              }
              @page :left {
                margin: 0cm;
              }

              @page :right {
                margin: 0cm;
              }
              .not-printable {
                display: none !important;
              }
              body {
                -webkit-print-color-adjust: exact !important;
                height: 100% !important;
                width: 100% !important;
                padding: 0px !important;
                margin: 0px !important;
                color: #000;
                background-color: #fff !important;
                page-break-before: avoid;
                page-break-before: avoid;
              }
              .adjust-main {
                padding: 0px !important;
              }
              header nav,
              footer {
                display: none;
              }
              h3 {
                font-size: 11px !important;
              }
              h5 {
                font-size: 9px !important;
              }

              .center-print {
                background: #ffffff !important;
              }

              .image-print {
                width: 100px !important;
                height: 100px !important;
                text-align: center !important;
                margin: auto !important;
              }
              span {
                font-size: 10px !important;
                font-weight: bolder !important;
              }
              .center-print {
                text-align: center !important;
              }
            }
            img {
              padding: 5px;
            }
            .image-print {
              width: 80px !important;
              height: 80px !important;
              text-align: center !important;
              margin: auto !important;
            }
            span {
              font-size: 10px !important;
              font-weight: bolder !important;
            }

            .center-print {
              text-align: center !important;
              background: #ffffff !important;
            }
            .print-padding {
              padding: 5px !important;
            }
            </style>
            <body >
              <div data-v-4a284cfa="" class="center-print" style="border: 2px solid rgb(37, 37, 37); margin-top: 5px;height:100vh">
                <div data-v-4a284cfa="">

                  <div data-v-4a284cfa="" class="row print-padding">
                  <div data-v-4a284cfa="" class="col col-4">
                    <h3 data-v-4a284cfa="">رقم الوصل : ${voucher.voucherNumber}</h3>
                    <h3 data-v-4a284cfa="">تاريخ الوصل : ${voucher.paymentDateFormat}</h3>
                  </div>
                  <div data-v-4a284cfa="" class="col col-4">
                    <div data-v-4a284cfa="" class="v-image v-responsive my-3 theme--light" style="height: 90px;margin:0px">
                      <div class="v-responsive__sizer" style="padding-bottom: 99.5851%"></div>
                      <div class="v-responsive__content" style="width: 241px"></div>
                    </div>
                  </div>
                  <div data-v-4a284cfa="" class="col col-4">
                    <h3 data-v-4a284cfa="" style="padding: 10px">نسية التحصيل الاجمالية : ٪0 </h3>
                  </div>
                </div>

                    <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                    <div data-v-4a284cfa="" class="row print-padding" style="margin-top:10px !important">
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">انا الموقع ادناه</h3></div>
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">عن</h3></div>
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">I Certify</h3></div>
                    </div>
                    <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                    <h3 data-v-4a284cfa="" style="padding: 10px">استلمت من الطالب / ة ${self.$store.state.studentLevelOne.studentName} / ذي الرقم الجامعي ${voucher.collegeNumber}</h3>
                    <h3 data-v-4a284cfa="" style="padding: 10px">( القسم : ${self.sectionCost.sectionName} / حجز مقعد / الدراسة : ${
        self.$store.state.studentLevelOne.firstStudyType == 1 || self.$store.state.studentLevelOne.firstStudyType == "صباحي" ? "صباحي" : "مسائي"
      } )</h3>
                    <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                    <div data-v-4a284cfa="" class="row print-padding">
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">مبلغا وقدره</h3></div>
                      <div data-v-4a284cfa="" class="col col-4">
                        <h3 data-v-4a284cfa="" style="display: inline-block; margin-left: 10px">${voucher.amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "IQD",
                        })}</h3>
                        <h3 data-v-4a284cfa="" style="display: inline-block">${numberWriting} فقط لاغير</h3>
                      </div>
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Amount Of</h3></div>
                    </div>
                    <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                    <div data-v-4a284cfa="" class="row print-padding">
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">وذلك عن</h3></div>
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">${voucher.typeName}</h3></div>
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">For</h3></div>
                    </div>
                    <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                    <div data-v-4a284cfa="" class="row print-padding">
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">ملاحظات</h3></div>
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">${voucher.notes}</h3></div>
                      <div data-v-4a284cfa="" class="col col-4"><h3 data-v-4a284cfa="">Notes</h3></div>
                    </div>
                    <hr data-v-4a284cfa="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="background: rgb(0, 0, 0)" />
                  </div>
                </div>
              </div>
            </body>
          </html>
          `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
  },

  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else {
        return "متخرج";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
