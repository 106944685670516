import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import Toasted from "vue-toasted";
import VuetifyMoney from "vuetify-money";
import VueLazyload from "vue-lazyload";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.config.productionTip = false;
Vue.use(VueLazyload);
Vue.http = Vue.prototype.$http = axios;

// Load user's preferred theme from localStorage
// Retrieve the saved theme name from localStorage
const savedTheme = localStorage.getItem("userTheme");

// If a theme is saved, apply it to the Vuetify instance
if (savedTheme) {
  const themes = {
    scheme1: {
      primary: "#4a4e69",
      secondary: "#22223b",
      accent: "#e4e4e4",
      background: "#e4e4e4",
      error: "#E0144C",
      dark: "#023047",
      info: "#3C79AF",
      stepper: "#D4F5F3",
      success: "#1b998b",
      warning: "#fb8500",
    },
    scheme2: {
      primary: "#fb6f92",
      secondary: "#d6e0f0",
      accent: "#ffffff",
      background: "#e4e4e4",
      error: "#E0144C",
      dark: "#023047",
      info: "#3C79AF",
      stepper: "#D4F5F3",
      success: "#1b998b",
      warning: "#fb8500",
    },
    scheme3: {
      primary: "#335c67",
      secondary: "#fff3b0",
      accent: "#e09f3e",
      background: "#e4e4e4",
      error: "#E0144C",
      dark: "#023047",
      info: "#3C79AF",
      stepper: "#D4F5F3",
      success: "#1b998b",
      warning: "#fb8500",
    },
    scheme4: {
      primary: "#252422",
      secondary: "#ccc5b9",
      accent: "#fffcf2",
      background: "#e4e4e4",
      error: "#E0144C",
      dark: "#023047",
      info: "#3C79AF",
      stepper: "#D4F5F3",
      success: "#1b998b",
      warning: "#eb5e28",
    },
  };
  const selectedTheme = themes[savedTheme];
  if (selectedTheme) {
    vuetify.framework.theme.themes.light = selectedTheme;
    vuetify.framework.theme.themes.dark = selectedTheme; // Optional for dark mode
  }
}

Vue.use(VuetifyMoney);
Vue.use(Toasted, {
  iconPack: "material",
  duration: 2000,
  position: "top-center",
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
