import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  rtl: true,
  theme: {
    options: {
      customProperties: true, // Enable CSS variables
    },
    themes: {
      scheme1: {
        primary: "#4a4e69",
        secondary: "#22223b",
        accent: "#e4e4e4",
        background: "#e4e4e4",
        error: "#E0144C",
        dark: "#023047",
        info: "#3C79AF",
        stepper: "#D4F5F3",
        success: "#1b998b",
        warning: "#fb8500",
      },
      scheme2: {
        primary: "#fb6f92",
        secondary: "#d6e0f0",
        accent: "#ffffff",
        background: "#e4e4e4",
        error: "#E0144C",
        dark: "#023047",
        info: "#3C79AF",
        stepper: "#D4F5F3",
        success: "#1b998b",
        warning: "#fb8500",
      },
      scheme3: {
        primary: "#335c67",
        secondary: "#fff3b0",
        accent: "#e09f3e",
        background: "#e4e4e4",
        error: "#E0144C",
        dark: "#023047",
        info: "#3C79AF",
        stepper: "#D4F5F3",
        success: "#1b998b",
        warning: "#fb8500",
      },
      scheme4: {
        primary: "#252422",
        secondary: "#ccc5b9",
        accent: "#fffcf2",
        background: "#e4e4e4",
        error: "#E0144C",
        dark: "#023047",
        info: "#3C79AF",
        stepper: "#D4F5F3",
        success: "#1b998b",
        warning: "#eb5e28",
      },
      light: {
        primary: "#fb6f92",
        secondary: "#d6e0f0",
        background: "#e4e4e4",
        accent: "#ffffff",
        error: "#E0144C",
        dark: "#023047",
        info: "#3C79AF",
        stepper: "#D4F5F3",
        success: "#1b998b",
        warning: "#fb8500",
      },
      dark: {
        primary: "#363636",
        secondary: "#d6e0f0",
        background: "#fff",
        accent: "#006a71",
        error: "#ff6b6b",
        info: "#2196F3",
        dark: "#363636",
        stepper: "#ebffeb",
        success: "#1b998b",
        warning: "#ffe66d",
      },
    },
  },
});
