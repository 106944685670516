<template>
  <div>
    <v-dialog v-model="show" persistent max-width="800px" width="800px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>تثبيت الطالب</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="padding: 10px">
            <h3>معلومات الطالب</h3>
            <div style="padding: 10px"></div>
            <v-divider></v-divider>
            <div style="margin-top: 10px"></div>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="4"> اسم الطالب :</v-col>
                  <v-col cols="8"><v-text-field dense v-model="$store.state.studentLevelOne.studentName" label="الاسم" single-line hide-details outlined></v-text-field></v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h3>القسم : {{ $store.state.studentLevelOne.sectionName }}</h3>
                <div style="height: 10px"></div>
                <v-autocomplete dense v-model="sectionId" outlined label="القسم" :items="$store.state.sections" item-text="sectionName" item-value="idSection"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6"> ايميل الطالب : {{ $store.state.studentLevelOne.collegeNumber }}<span>@uoturath.edu.iq</span></v-col>
              <v-col cols="6"> تاريخ الميلاد : {{ $store.state.studentLevelOne.dob }} </v-col>
            </v-row>

            <v-row>
              <v-col cols="6"> الجنسية : عراقية </v-col>
              <v-col cols="6">الجنس : {{ $store.state.studentLevelOne.gender == "1" || $store.state.studentLevelOne.gender == "ذكر" ? "ذكر" : "انثى" }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="6"
                >الدراسة :
                <v-radio-group v-model="row" row style="display: inline-block" hide-details>
                  <v-radio label="صباحي" value="1"></v-radio>
                  <v-radio label="مسائي" value="2"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">الرقم الجامعي : {{ $store.state.studentLevelOne.collegeNumber }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="4"> قناة القبول : </v-col>
                  <v-col cols="8">
                    <v-autocomplete
                      dense
                      v-model="acceptedTypeId"
                      outlined
                      label="قناة القبول"
                      :items="$store.state.acceptedTypes"
                      item-text="acceptedName"
                      item-value="idAcceptedType"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">الملاحظات : {{ $store.state.studentLevelOne.notes }}</v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="saveData" v-on:keyup.enter="saveData"> تثبيت </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" color="primary" @click="show = true" v-bind="attrs" v-on="on" depressed tile>
          <v-icon dark> mdi-account-check </v-icon>
          تثبيت الطالب
        </v-btn>
      </template>
      <span>تثبيت الطالب</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
  },
  data: () => ({
    studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
    show: false,
    discount: "",
    acceptedName: "",
    row: "1",
    sectionId: null,
    acceptedTypeId: null,
  }),
  created() {
    console.log(this.$store.state.studentLevelOne);
    console.log(this.getAccepted());
    this.acceptedTypeId = this.getAccepted();
  },
  methods: {
    saveData() {
      let self = this;

      let newStudent = {
        studentName: self.$store.state.studentLevelOne.studentName,
        collegeNumber: self.$store.state.studentLevelOne.collegeNumber,
        englishName: "none",
        mail: `${self.$store.state.studentLevelOne.collegeNumber}@uoturath.edu.iq`,
        dob: self.$store.state.studentLevelOne.dob,
        sectionId: self.sectionId,
        nationality: "عراقية",
        gender: self.$store.state.studentLevelOne.gender == "1" || self.$store.state.studentLevelOne.gender == "ذكر" ? "ذكر" : "انثى",
        studyType: self.row * 1,
        note: self.$store.state.studentLevelOne.notes,
        registerYearId: 75,
        studentStatusId: 4,
        acceptedTypeId: self.acceptedTypeId != null ? self.acceptedTypeId : self.getAccepted(),
        id: self.$store.state.studentLevelOne.idApplication,
        // acceptedTypeId:getAccepted()
        //   self.$store.state.studentLevelOne.acceptedTypeId == "القناة العامة"
        //     ? 4
        //     : self.$store.state.acceptedTypes.filter((ac) => ac.acceptedName == self.$store.state.studentLevelOne.acceptedTypeId)[0].idAcceptedType,
      };

      console.log(newStudent);

      this.$http
        .post(`${this.$store.state.apiUrlRegistration}/v2/confirmStudent`, newStudent, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.show = false;
          this.$emit("clicked", res.data);
          this.$toasted.success("تم تثبيت الطالب البيانات");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
    getAccepted() {
      let self = this;
      if (self.$store.state.studentLevelOne.acceptedTypeId == "القناة العامة") {
        return 4;
      } else if (self.$store.state.studentLevelOne.acceptedTypeId == "قناة ذوي الشهداء") {
        return 5;
      } else if (self.$store.state.studentLevelOne.acceptedTypeId == "ابناء الهيئة التدريسية") {
        return 1;
      } else if (self.$store.state.studentLevelOne.acceptedTypeId == "ابناء حملة الشهادات العليا") {
        return 2;
      } else if (self.$store.state.studentLevelOne.acceptedTypeId == "الاعلاميين") {
        return 44;
      } else if (self.$store.state.studentLevelOne.acceptedTypeId == "الرياضيين") {
        return 3;
      } else if (self.$store.state.studentLevelOne.acceptedTypeId == "ذوي الاعاقة") {
        return 22;
      } else if (self.$store.state.studentLevelOne.acceptedTypeId == "منحة مجانية") {
        return 7;
      } else if (self.$store.state.studentLevelOne.acceptedTypeId == "الرعاية") {
        return 66;
      } else {
        return self.$store.state.acceptedTypes.filter((ac) => ac.acceptedName == self.$store.state.studentLevelOne.acceptedTypeId)[0].idAcceptedType;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
