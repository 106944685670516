<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="8">
            <h3>{{ $store.state.userData.employeeName }}</h3>
          </v-col>
          <v-col cols="4" align="left">
            <ChangePassword style="display: inline-block" />
            <v-btn class="mx-2" style="display: inline-block" color="primary" @click="logout()">
              <v-icon dark> mdi-logout-variant </v-icon>
              تسجيل خروج
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <theme-switcher />

    <v-container class="container" fluid v-if="$store.state.userData.roleId != 61">
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">الرئيسي</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalMain.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">الاستمارات</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalCertificate.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">الهويات</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalIdentity.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">هويات حجز مقعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalIdentityBoxReserved.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">اقساط حجز مقعد</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalMainBoxReserved.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">ادامة اثاث الكلية</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalFurnitureBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق اقساط المرحلة الاولى</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalMainLevelOneBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق هويات المرحلة الاولى</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalIdentityLevelOneBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق خدمات المرحلة الاولى</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalServiceLevelOneBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق التأييدات</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalConfirmBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 20px" max-width="344" color="accent">
              <v-row>
                <v-col cols="4">
                  <v-icon style="background: white" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                </v-col>
                <v-col cols="8" class="division-title">
                  <h4 class="warning-color" style="font-size: 1em; font-weight: bold">صندوق التدريب الصيفي لطب الاسنان</h4>
                </v-col>
              </v-row>
              <h4 class="warning-color" style="font-size: 1.8em; font-weight: lighter">
                {{
                  totalDentilBox.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </v-card>
          </v-col>
        </v-row>
        <div style="padding: 30px">
          <v-row align="center" justify="space-around">
            <v-btn tile color="success" @click="lockBox" :disabled="locked">
              <v-icon left> mdi-lock </v-icon>
              قفل الصندوق
            </v-btn>
          </v-row>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ChangePassword from "./../components/ChangePassword.vue";
import ThemeSwitcher from "./../components/ThemeSwitcher.vue";

export default {
  components: {
    ChangePassword,
    ThemeSwitcher,
  },
  data() {
    return {
      locked: false,
      totalMain: 0,
      totalIdentity: 0,
      totalCertificate: 0,
      totalIdentityBoxReserved: 0,
      totalMainBoxReserved: 0,
      overlay: false,
      totalFurnitureBox: 0,

      totalMainLevelOneBox: 0,
      totalIdentityLevelOneBox: 0,
      totalServiceLevelOneBox: 0,
      totalConfirmBox: 0,
      totalDentilBox: 0,

      selectedItem: 0,
      itemsTow: [
        {
          action: "mdi-ticket",
          items: [],
          title: "Attractions",
        },
        {
          action: "mdi-silverware-fork-knife",
          active: true,
          items: [{ title: "Breakfast & brunch" }, { title: "New American" }, { title: "Sushi" }],
          title: "Dining",
        },
        {
          action: "mdi-school",
          items: [{ title: "List Item" }],
          title: "Education",
        },
        {
          action: "mdi-human-male-female-child",
          items: [{ title: "List Item" }],
          title: "Family",
        },
        {
          action: "mdi-bottle-tonic-plus",
          items: [{ title: "List Item" }],
          title: "Health",
        },
        {
          action: "mdi-briefcase",
          items: [{ title: "List Item" }],
          title: "Office",
        },
        {
          action: "mdi-tag",
          items: [{ title: "List Item" }],
          title: "Promotions",
        },
      ],
    };
  },
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;

      self.overlay = true;
      let date = new Date();
      let fullDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

      console.log(fullDate);

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlFees}/receiptsSearch?dates=${JSON.stringify([fullDate, fullDate])}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/expensesSearch?dates=${JSON.stringify([fullDate, fullDate])}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/boxAmountByDate/${fullDate}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
        ])
        .then(
          self.$http.spread((receipts, expenses, box) => {
            let expensesCertificateBox = expenses.data.filter((expense) => expense.boxId == 7);
            let expensesIdentityBox = expenses.data.filter((expense) => expense.boxId == 2);
            let expensesIdentityBoxReserved = expenses.data.filter((expense) => expense.boxId == 9);
            let expensesMainBoxReserved = expenses.data.filter((expense) => expense.boxId == 10);
            let expensesFurnitureBox = expenses.data.filter((expense) => expense.boxId == 11);

            let expensesMainLevelOne = expenses.data.filter((expense) => expense.boxId == 15);
            let expensesIdentityLevelOne = expenses.data.filter((expense) => expense.boxId == 16);
            let expensesServicesLevelOne = expenses.data.filter((expense) => expense.boxId == 18);
            let expensesConfirm = expenses.data.filter((expense) => expense.boxId == 4);

            let receiptMainLevelOne = receipts.data.filter((receipt) => receipt.boxId == 15);
            let receiptIdentityLevelOne = receipts.data.filter((receipt) => receipt.boxId == 16);
            let receiptServicesLevelOne = receipts.data.filter((receipt) => receipt.boxId == 18);
            let receiptConfirm = receipts.data.filter((receipt) => receipt.boxId == 4);

            let expensesDentilBox = expenses.data.filter((expense) => expense.boxId == 21);
            let receiptDentilBox = receipts.data.filter((receipt) => receipt.boxId == 21);

            let expensesMainBox = expenses.data.filter(
              (expense) =>
                expense.boxId != 2 &&
                expense.boxId != 7 &&
                expense.boxId != 9 &&
                expense.boxId != 11 &&
                expense.boxId != 12 &&
                expense.boxId != 13 &&
                expense.boxId != 14 &&
                expense.boxId != 15 &&
                expense.boxId != 16 &&
                expense.boxId != 17 &&
                expense.boxId != 4 &&
                expense.boxId != 21 &&
                expense.boxId != 18
            );

            let receiptMainBox = receipts.data.filter(
              (receipt) =>
                receipt.boxId != 2 &&
                receipt.boxId != 7 &&
                receipt.boxId != 9 &&
                receipt.boxId != 11 &&
                receipt.boxId != 12 &&
                receipt.boxId != 13 &&
                receipt.boxId != 14 &&
                receipt.boxId != 15 &&
                receipt.boxId != 16 &&
                receipt.boxId != 17 &&
                receipt.boxId != 4 &&
                receipt.boxId != 21 &&
                receipt.boxId != 18
            );
            let receiptCertificateBox = receipts.data.filter((receipt) => receipt.boxId == 7);
            let receiptIdentityBox = receipts.data.filter((receipt) => receipt.boxId == 2);
            let receiptIdentityBoxReserved = receipts.data.filter((receipt) => receipt.boxId == 9);
            let receiptMainBoxReserved = receipts.data.filter((receipt) => receipt.boxId == 10);
            let receiptFurnitureBox = receipts.data.filter((receipt) => receipt.boxId == 11);

            let totalDentilBox =
              receiptDentilBox.reduce((n, { amount }) => n + amount, 0) +
              (self.$store.state.boxAmounts.find((box) => box.boxId == 21) ? self.$store.state.boxAmounts.find((box) => box.boxId == 21).amount : 0) -
              expensesDentilBox.reduce((n, { amount }) => n + amount, 0);

            let totalMainLevelOneBoxReserved =
              receiptMainLevelOne.reduce((n, { amount }) => n + amount, 0) +
              (self.$store.state.boxAmounts.find((box) => box.boxId == 15) ? self.$store.state.boxAmounts.find((box) => box.boxId == 15).amount : 0) -
              expensesMainLevelOne.reduce((n, { amount }) => n + amount, 0);

            let totalIdentityLevelOneBoxReserved =
              receiptIdentityLevelOne.reduce((n, { amount }) => n + amount, 0) +
              (self.$store.state.boxAmounts.find((box) => box.boxId == 16) ? self.$store.state.boxAmounts.find((box) => box.boxId == 16).amount : 0) -
              expensesIdentityLevelOne.reduce((n, { amount }) => n + amount, 0);

            let totalServicesLevelOneBoxReserved =
              receiptServicesLevelOne.reduce((n, { amount }) => n + amount, 0) +
              (self.$store.state.boxAmounts.find((box) => box.boxId == 18) ? self.$store.state.boxAmounts.find((box) => box.boxId == 18).amount : 0) -
              expensesServicesLevelOne.reduce((n, { amount }) => n + amount, 0);

            let totalConfirmBoxReserved =
              receiptConfirm.reduce((n, { amount }) => n + amount, 0) +
              (self.$store.state.boxAmounts.find((box) => box.boxId == 4) ? self.$store.state.boxAmounts.find((box) => box.boxId == 4).amount : 0) -
              expensesConfirm.reduce((n, { amount }) => n + amount, 0);

            console.log(totalConfirmBoxReserved);

            let totalMainBoxReserved =
              receiptMainBoxReserved.reduce((n, { amount }) => n + amount, 0) +
              self.$store.state.boxAmounts.find((box) => box.boxId == 10).amount -
              expensesMainBoxReserved.reduce((n, { amount }) => n + amount, 0);

            let totalIdentityBoxReserved =
              receiptIdentityBoxReserved.reduce((n, { amount }) => n + amount, 0) +
              self.$store.state.boxAmounts.find((box) => box.boxId == 9).amount -
              expensesIdentityBoxReserved.reduce((n, { amount }) => n + amount, 0);

            let totalFurnitureBox =
              receiptFurnitureBox.reduce((n, { amount }) => n + amount, 0) +
              self.$store.state.boxAmounts.find((box) => box.boxId == 11).amount -
              expensesFurnitureBox.reduce((n, { amount }) => n + amount, 0);

            let totalMain =
              receiptMainBox.reduce((n, { amount }) => n + amount, 0) + self.$store.state.boxAmounts.find((box) => box.boxId == 1).amount - expensesMainBox.reduce((n, { amount }) => n + amount, 0);
            console.log(totalMain);
            let totalIdentity =
              receiptIdentityBox.reduce((n, { amount }) => n + amount, 0) +
              self.$store.state.boxAmounts.find((box) => box.boxId == 2).amount -
              expensesIdentityBox.reduce((n, { amount }) => n + amount, 0);
            let totalCertificate =
              receiptCertificateBox.reduce((n, { amount }) => n + amount, 0) +
              self.$store.state.boxAmounts.find((box) => box.boxId == 7).amount -
              expensesCertificateBox.reduce((n, { amount }) => n + amount, 0);
            console.log(expensesIdentityBox);
            self.totalCertificate = totalCertificate;
            self.totalIdentity = totalIdentity;
            self.totalMain = totalMain;
            self.totalFurnitureBox = totalFurnitureBox;
            self.totalMainBoxReserved = totalMainBoxReserved;
            self.totalIdentityBoxReserved = totalIdentityBoxReserved;
            self.totalMainLevelOneBox = totalMainLevelOneBoxReserved;
            self.totalIdentityLevelOneBox = totalIdentityLevelOneBoxReserved;
            self.totalServiceLevelOneBox = totalServicesLevelOneBoxReserved;
            self.totalConfirmBox = totalConfirmBoxReserved;
            self.totalDentilBox = totalDentilBox;
            self.overlay = false;
            console.log(box.data);

            self.locked = box.data.length > 0 ? true : false;
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          // this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    // SELECT studentId, COUNT(studentId) FROM `StudentLevel` GROUP BY studentId HAVING COUNT(studentId) > 3
    logout() {
      localStorage.removeItem("employee");
      location.reload();
    },
    changed(child) {
      console.log(child.target);
      // child.target.classList.add("active-div");
    },
    lockBox() {
      let self = this;
      let date = new Date();
      let fullDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      let boxLock = [
        { amount: self.totalMain, date: fullDate, boxId: 1 },
        { amount: self.totalCertificate, date: fullDate, boxId: 7 },
        { amount: self.totalIdentity, date: fullDate, boxId: 2 },
        { amount: self.totalMainBoxReserved, date: fullDate, boxId: 10 },
        { amount: self.totalIdentityBoxReserved, date: fullDate, boxId: 9 },
        { amount: self.totalFurnitureBox, date: fullDate, boxId: 11 },
        { amount: self.totalMainLevelOneBox, date: fullDate, boxId: 15 },
        { amount: self.totalIdentityLevelOneBox, date: fullDate, boxId: 16 },
        { amount: self.totalServiceLevelOneBox, date: fullDate, boxId: 18 },
        { amount: self.totalConfirmBox, date: fullDate, boxId: 4 },
        { amount: self.totalDentilBox, date: fullDate, boxId: 21 },
      ];
      console.log(boxLock);
      self.$http
        .post(`${self.$store.state.apiUrlFees}/addMultiBoxAmount`, boxLock, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          console.log(res.data);
          self.initialData();
          this.$toasted.success("تم قفل الصندوق");
        })
        .catch((err) => {
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.active-div {
  background: red;
}
</style>
