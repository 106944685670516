import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // apiUrl: "http://5.5.5.10:4320/api",
    // apiUrlFees: "http://5.5.5.10:4310/api",
    // apiUrlRegistration: "http://5.5.5.10:4300/api",
    // apiUrlApplication: "http://5.5.5.10:4340/api",
    // apiUrlArchive: "http://5.5.5.10:5100/api",

    // apiUrl: "http://localhost:4320/api",
    // apiUrlFees: "http://localhost:4310/api",
    // apiUrlRegistration: "http://localhost:4300/api",
    // apiUrlApplication: "http://localhost:4340/api",
    // apiUrlArchive: "http://localhost:5100/api",

    apiUrl: "https://employee.al-turath.university/api",
    apiUrlApplication: "https://form.al-turath.university/api",
    apiUrlFees: "https://finance.al-turath.university/api",
    apiUrlRegistration: "https://student.al-turath.university/api",
    apiUrlArchive: "https://archive.al-turath.university/api",
    // apiUrlLocal: "http://localhost:4310/api",

    sections: null,
    allSections: null,
    subjects: null,
    insideOriginalArray: null,
    years: null,
    user: null,
    studentsContinue: 0,
    userData: null,
    paymentTypesNotInstallment: [],
    paymentTypesInstallment: [],
    paymentTypesAll: [],
    boxAmounts: [],
    studentPay: {},
    items: [],
    subList: [],
    acceptedTypes: null,
    printInformation: "",
    studentsStatus: [
      { idStudentStatus: 1, statusName: "مستمر" },
      { idStudentStatus: 2, statusName: "محجوب" },
      { idStudentStatus: 3, statusName: "متخرج" },
      { idStudentStatus: 4, statusName: "طالب جديد" },
      { idStudentStatus: 5, statusName: "خروج بامر اداري" },
      { idStudentStatus: 6, statusName: "مؤجل" },
      { idStudentStatus: 7, statusName: "غير فعال" },
      { idStudentStatus: 8, statusName: "فعال" },
      { idStudentStatus: 9, statusName: "غير ملتحق بالدراسة" },
    ],
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
      { levelName: "المرحلة الخامسة", level: 5 },
    ],
    reportFees: "",
    studentDetails: null,
    subFeeStudentDetails: null,
    statistics: null,
    hideLogin: false,
  },
  mutations: {
    GET_USER_DATA(state, userData) {
      state.user = userData;
    },
  },
  actions: {
    getUserDate({ commit }) {},
  },
  modules: {},
});
