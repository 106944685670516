import { render, staticRenderFns } from "./StudentLevelOneDetails.vue?vue&type=template&id=0d2f5908&scoped=true&"
import script from "./StudentLevelOneDetails.vue?vue&type=script&lang=js&"
export * from "./StudentLevelOneDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d2f5908",
  null
  
)

export default component.exports