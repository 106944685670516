<template>
  <v-container>
    <v-row>
      <!-- Loop through themes to create buttons -->
      <v-btn style="margin: 0 5px" v-for="(scheme, name) in themes" :key="name" :style="{ backgroundColor: scheme.primary, color: '#fff' }" @click="setTheme(name)"> </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      // Define your themes
      themes: {
        scheme1: {
          primary: "#293241",
          secondary: "#98c1d9",
          accent: "#e0fbfc",
          background: "#e4e4e4",
          error: "#E0144C",
          dark: "#023047",
          info: "#3C79AF",
          stepper: "#D4F5F3",
          success: "#1b998b",
          warning: "#fb8500",
        },
        scheme2: {
          primary: "#fb6f92",
          secondary: "#d6e0f0",
          accent: "#ffffff",
          background: "#e4e4e4",
          error: "#E0144C",
          dark: "#023047",
          info: "#3C79AF",
          stepper: "#D4F5F3",
          success: "#1b998b",
          warning: "#fb8500",
        },
        scheme3: {
          primary: "#284b63",
          secondary: "#edf2f4",
          accent: "#ffffff",
          background: "#e4e4e4",
          error: "#E0144C",
          dark: "#023047",
          info: "#353535",
          stepper: "#D4F5F3",
          success: "#1b998b",
          warning: "#fb8500",
        },
        scheme4: {
          primary: "#252422",
          secondary: "#ccc5b9",
          accent: "#fffcf2",
          background: "#e4e4e4",
          error: "#E0144C",
          dark: "#023047",
          info: "#3C79AF",
          stepper: "#D4F5F3",
          success: "#1b998b",
          warning: "#eb5e28",
        },
      },
    };
  },
  methods: {
    setTheme(themeName) {
      // Dynamically change the Vuetify theme
      const selectedTheme = this.themes[themeName];
      this.$vuetify.theme.themes.light = selectedTheme; // Apply to light theme
      this.$vuetify.theme.themes.dark = selectedTheme; // Apply to dark theme if needed

      // Save the selected theme name in localStorage
      localStorage.setItem("userTheme", themeName);
    },
  },
  mounted() {
    // Load the theme from localStorage if available
    const savedTheme = localStorage.getItem("userTheme");
    if (savedTheme && this.themes[savedTheme]) {
      this.$vuetify.theme.themes.light = this.themes[savedTheme];
    }
  },
};
</script>
